.ql-container {
    min-height: 10rem;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ql-editor {
    height: 100%;
    flex: 1;
    overflow-y: auto;
    width: 100%;
}
.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #22a6b3;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: 10px auto;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
